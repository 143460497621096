/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		Bruce Legal
		Date:		16th July 2018
		Author:		Edge Marketing Solutions

===========================================================================*/
/* [Global Variables] - http://sass-lang.com/guide */
/*=========================================================================*/
/* [Global Template Styles] */
body {
  color: #4c4c4c;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

a {
  -webkit-transition: all 150ms ease-in-out;
  -moz-transition: all 150ms ease-in-out;
  -ms-transition: all 150ms ease-in-out;
  -o-transition: all 150ms ease-in-out;
  transition: all 150ms ease-in-out;
  color: #730000;
}

a:hover {
  color: #730000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #060606;
  font-weight: 600;
}

h1 {
  margin: 0 0 1.5rem 0;
  font-size: 3rem;
}

h2,
h3,
h4,
h5 {
  margin: 1.5rem 0;
}

h6 {
  margin-top: 1.5rem;
  font-weight: 700;
}

.btn {
  border-radius: 0;
  border: none;
}

.btn-primary {
  background-color: #730000;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background-color: #060606;
}

img {
  max-width: 100%;
}

img.svg-responsive {
  width: 100% \9;
}

img.img-lightbox {
  cursor: zoom-in;
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  h1 {
    font-size: 2.5rem;
  }
}

/*=========================================================================*/
/* [Header Template Styles] */
.header {
  padding: 2.5rem;
  position: relative;
}

.header .overlay-pattern {
  z-index: -1;
  overflow: hidden;
}

.header .overlay-pattern img {
  margin-right: -10vw;
}

.header .overlay-pattern img {
  width: 100%;
  height: 100%;
}

.header .social {
  display: inline-block;
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .header .overlay-pattern img {
    width: 100%;
    height: 100%;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .header .overlay-pattern img {
    width: 100%;
    height: 100%;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .header {
    padding: 1.5rem 0.75rem;
  }
  .header .overlay-pattern {
    opacity: 0.1;
  }
  .header .overlay-pattern img {
    width: 100%;
    height: 100%;
  }
}

/*=========================================================================*/
/* [Navigation Template Styles] */
.navbar-light {
  padding: 0;
}

.navbar-light .navbar-nav {
  width: 100%;
}

.navbar-light .navbar-nav .nav-link {
  padding: 0 0.8rem;
  color: #060606;
  text-transform: uppercase;
  font-size: 0.95rem;
}

.navbar-light .navbar-nav .nav-item:first-child .nav-link {
  padding-left: 0;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #730000;
}

.navbar-light .navbar-nav .dropdown-menu {
  border-radius: 0;
}

.navbar-light .dropdown-item {
  text-align: center;
}

.navbar-light .dropdown-item.child-item {
  font-size: 85%;
  background-color: #b2b2b2;
}

.navbar-light .dropdown-item.active,
.navbar-light .dropdown-item:active {
  background-color: #730000;
}

.navbar-light .navbar-toggler {
  width: 100%;
  border: 1px solid #4c4c4c;
}

/* Large desktop */
@media (min-width: 1200px) {
  .navbar-nav .dropdown-menu {
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-light .navbar-nav .nav-link {
    padding: 0 1rem;
    font-size: 0.9rem;
  }
}

@media (min-width: 768px) {
  .dropdown-item.child-item {
    display: none;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .navbar-light .navbar-nav .nav-link {
    padding: 0 1rem;
    font-size: 0.8rem;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .navbar-light .navbar-nav {
    border: 1px solid #4c4c4c;
    background-color: white;
  }
  .navbar-light .navbar-nav .nav-link {
    padding: 1rem 0;
    width: 100%;
    text-align: center;
  }
  .navbar-light .navbar-nav .dropdown-menu {
    padding: 0;
    background-color: rgba(76, 76, 76, 0.2);
  }
  .navbar-light .dropdown-item {
    padding: 0.5rem 0.25rem;
    font-weight: bold;
  }
  .navbar-light .dropdown-item.child-item {
    background-color: rgba(255, 255, 255, 0.5);
    font-weight: normal;
  }
  .navbar-light .dropdown-item.active,
  .navbar-light .dropdown-item:active {
    background-color: transparent;
    color: #730000;
  }
}

/*=========================================================================*/
/* [Page Template Styles] */
.page {
  width: 100%;
  padding: 3rem 2rem;
}

.sidenav {
  position: relative;
  padding: 0 0 3rem 2rem;
}

.sidenav h6 {
  margin-top: 0;
}

.sidenav .nav-link {
  padding: 0.5rem 0;
  color: #4c4c4c;
}

.sidenav .nav .nav-link {
  padding: 0.5rem 0 0.5rem 1rem;
}

.sidenav .active {
  font-weight: bold;
  color: #730000;
}

.sidenav .active > .nav-link {
  color: #730000;
}

.sidenav .active .nav {
  padding: 0.25rem;
  background-color: rgba(76, 76, 76, 0.05);
  font-weight: normal;
  font-size: 0.9rem;
}

.sidenav:before {
  position: absolute;
  content: ' ';
  width: 3rem;
  height: calc(100% + 3rem);
  top: -3rem;
  right: 100%;
  background-image: url(../images/vector-bg-light.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.breadcrumb {
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: 0.7rem;
}

.overlay-pattern {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
}

.subpage-banner {
  position: relative;
}

.rotator {
  position: relative;
}

.swiper-container {
  width: 100%;
  position: relative;
}

.swiper-container .swiper-slide img {
  width: 100%;
}

.swiper-container .swiper-text {
  position: absolute;
  bottom: 3rem;
  width: 50%;
  left: 3rem;
  z-index: 3000;
  color: #060606;
  font-size: 2.7rem;
}

.swiper-container .swiper-text .subtitle {
  font-size: 3.2rem;
  font-weight: 600;
}

.swiper-container .swiper-slide-image {
  position: relative;
}

.swiper-container .swiper-slide-image:before {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  content: ' ';
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+30,ffffff+100&0+30,0.7+100 */
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.7) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.7) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.7) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#b3ffffff',GradientType=1 );
  /* IE6-9 */
}

.infobox-seminar {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 300px;
  z-index: 3000;
  padding: 2rem;
  background-color: white;
  background-image: url(../images/vector-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
}

.infobox-seminar h3 {
  margin: 0 0 0.5rem;
  font-size: 1.2rem;
  color: #730000;
}

.infobox-seminar p {
  font-size: 0.8rem;
}

.infobox-seminar:before {
  width: 100%;
  height: 100%;
  position: absolute;
  content: ' ';
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: -1;
}

.homepage {
  display: flex;
  width: 100%;
}

.homepage .homepage-body-wrapper {
  flex-basis: 35%;
  background-image: url(../images/homepage-content-bg.jpg);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 100% auto;
}

.homepage .homepage-body-wrapper .homepage-body {
  padding: 4rem;
  background-color: white;
}

.homepage .feature-nav-wrapper {
  flex-basis: 65%;
  background-image: url(../images/vector-bg-light.svg);
}

.homepage-secondary .feature-nav-wrapper {
  flex-basis: 60%;
  padding: 2rem;
  background-color: #730000;
  color: white;
}

.homepage-secondary .feature-nav-wrapper a,
.homepage-secondary .feature-nav-wrapper h3 {
  color: white;
}

.homepage-secondary .feature-nav-wrapper .form-control {
  background-color: transparent;
  border-radius: 0;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.homepage-secondary .feature-nav-wrapper .form-control::placeholder {
  color: white;
  opacity: 0.3;
}

.homepage-secondary .homepage-body-wrapper {
  flex-basis: 40%;
  background-image: none;
}

.feature-nav {
  padding: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.feature-nav .feature-nav-item {
  flex-basis: 50%;
  box-sizing: border-box;
  padding: 2rem;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  font-size: 0.9rem;
}

.feature-nav .feature-nav-item h3 {
  margin-top: 0;
  font-size: 1.3rem;
}

.feature-nav .feature-nav-item h3 a {
  color: #060606;
}

.feature-nav .feature-nav-item h3 a:hover {
  color: #730000;
  text-decoration: none;
}

.feature-nav .feature-nav-item:nth-child(even) {
  border-right: none;
}

.feature-nav .feature-nav-item:nth-last-child(-n+2) {
  border-bottom: none;
}

.front-form label {
  display: none;
}

.front-form .form-control {
  color: #fff;
}

/* Large desktop */
/* Desktop */
@media (min-width: 992px) and (max-width: 1199px) {
  .swiper-container .swiper-text {
    left: 2rem;
    font-size: 2.0rem;
  }
  .swiper-container .swiper-text .subtitle {
    font-size: 2.5rem;
  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
  .swiper-container .swiper-text {
    width: 45%;
    left: 1rem;
    font-size: 1.6rem;
  }
  .swiper-container .swiper-text .subtitle {
    font-size: 2rem;
  }
  .infobox-seminar {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 350px;
    z-index: 3000;
    padding: 1.5rem;
  }
  .infobox-seminar h3 {
    margin: 0 0 0.5rem;
    font-size: 1.0rem;
    color: #730000;
  }
  .infobox-seminar p {
    font-size: 0.7rem;
  }
  .homepage {
    display: block;
  }
  .homepage .homepage-body-wrapper {
    flex-basis: 100%;
    width: 100%;
  }
  .homepage .homepage-body-wrapper .homepage-body {
    padding: 4rem;
    background-color: white;
  }
  .homepage .feature-nav-wrapper {
    flex-basis: 100%;
  }
}

/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .page {
    width: 100%;
    padding: 3rem 0.75rem;
  }
  .sidenav {
    position: static;
    margin-top: 2rem;
    padding: 2rem 0 2rem 1.5rem;
    background-image: url(../images/vector-bg-light.svg);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .sidenav:before {
    display: none;
  }
  .swiper-container .swiper-text {
    width: auto;
    left: 1rem;
    bottom: 1rem;
    font-size: 1.2rem;
  }
  .swiper-container .swiper-text .subtitle {
    font-size: 1.6rem;
  }
  .swiper-container .swiper-slide-image:before {
    background-image: none;
    background-color: rgba(255, 255, 255, 0.3);
  }
  .infobox-seminar {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 3000;
    padding: 1.5rem;
  }
  .infobox-seminar h3 {
    margin: 0 0 0.5rem;
    font-size: 1.0rem;
    color: #730000;
  }
  .infobox-seminar p {
    font-size: 0.7rem;
  }
  .homepage {
    display: block;
  }
  .homepage .homepage-body-wrapper {
    flex-basis: 100%;
    width: 100%;
  }
  .homepage .homepage-body-wrapper .homepage-body {
    padding: 2rem;
    background-color: white;
  }
  .feature-nav-wrapper {
    width: 100%;
  }
  .feature-nav {
    padding: 0;
    display: block;
  }
  .feature-nav .feature-nav-item {
    flex-basis: 100%;
    width: 100%;
    padding: 2rem;
    border-right: none;
  }
}

/*=========================================================================*/
/* [Footer Template Styles] */
.footer {
  width: 100%;
  position: relative;
  padding: 2.5rem;
  background-image: url(../images/vector-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  font-size: 0.8rem;
}

.footer a {
  color: white;
}

.footer a:hover {
  color: #ffd6d6;
}

.footer h6 {
  margin: 0 0 0.25rem 0;
  font-size: 0.8rem;
  color: white !important;
  font-weight: 700;
}

.footer h6 a {
  color: white;
  text-decoration: none;
}

.footer .footnotes {
  opacity: 0.3;
}

.footer .nav-link {
  padding: 0.25rem 0;
}

.footer .social {
  font-size: 1.5rem;
}

.footer .team-outline {
  opacity: 0.1;
  background-position: 95% top;
}

.footer .btn:hover,
.footer .btn:focus {
  color: white;
}

.footer:before {
  position: absolute;
  content: ' ';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(76, 76, 76, 0.85);
}

/* Large desktop */
/* Desktop */
/* Portrait tablet to landscape and desktop */
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
  .footer {
    text-align: center;
  }
  .footer h6 {
    margin-top: 2rem;
  }
  .footer .footnotes {
    padding: 2rem 0;
  }
}

/*=========================================================================*/
/* [Table as row override Styles] */
table.table-as-row > tbody > tr:before,
table.table-as-row > tbody > tr:after {
  content: " ";
  display: table;
  clear: both;
}

table.table-as-row > tbody > tr {
  height: auto !important;
}

table.table-as-row,
table.table-as-row > tbody,
table.table-as-row > tbody > tr {
  display: block;
  width: auto;
}

table.table-as-row > tbody > tr {
  display: block;
  width: auto;
  margin-right: -15px;
  margin-left: -15px;
}

table.table-as-row > tbody > tr > td[class*=col-] {
  display: block;
  height: auto !important;
  margin-bottom: 20px;
}

/* col-lg */
@media (min-width: 1200px) {
  table.table-as-row > tbody > tr > td[class*=col-lg-] {
    float: left;
  }
}

/* col-md */
@media (min-width: 992px) {
  table.table-as-row > tbody > tr > td[class*=col-md-] {
    float: left;
  }
}

/* col-sm */
@media (min-width: 768px) {
  table.table-as-row > tbody > tr > td[class*=col-sm-] {
    float: left;
  }
}

/* col-xs */
table.table-as-row > tbody > tr > td[class*=col-xs-] {
  float: left;
}
